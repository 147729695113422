"use client";

import styles from "./index.module.scss";
import "./banners.scss";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss";
import Link from "next/link";
import Picture from "@/shared/components/Picture";
const Banners: FC<any> = props => {
  const {
    banners
  } = props;
  return <section className={styles.banners} data-sentry-component="Banners" data-sentry-source-file="index.tsx">
      <h2 className="visually-hidden">Главные баннеры ОхотАктив</h2>
      <Swiper className="swiper-main-banners" modules={[Autoplay, Pagination]} spaceBetween={32} loop={true} autoplay={{
      delay: 10000,
      disableOnInteraction: false
    }} pagination={{
      clickable: true
    }} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
        {banners.map((banner: any, index: number) => <SwiperSlide key={`${index + "swiperSlidekey" + "banners" + index}`}>
            {banner.property_link_to_value === null ? <div className={styles.banner_without__link}>
                <Picture className={styles.banner} alt={banner.name} width={1907} height={530} desktop={`https://ohotaktiv.ru${banner.property_picture_desktop_value}`} mobile={`https://ohotaktiv.ru${banner.property_picture_mobile_value}`} loading={"eager"} priority={true} />
              </div> : <Link prefetch={false} href={banner.property_link_to_value}>
                <Picture className={styles.banner} alt={banner.name} width={1907} height={530} desktop={`https://ohotaktiv.ru${banner.property_picture_desktop_value}`} mobile={`https://ohotaktiv.ru${banner.property_picture_mobile_value}`} loading={"eager"} priority={true} />
              </Link>}
          </SwiperSlide>)}
      </Swiper>
    </section>;
};
export default Banners;